import React, { FC } from 'react';
import TextLockup from '../components/TextLockup';
import { AppIcon } from 'common';
import { PageLayout } from '../components/layout';
import { MainContent } from '../components/layout/main-content';

const NotFoundPage: FC = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <MainContent cls="grid grid-cols-5 lg:grid-cols-14 gap-y-8 mt-4 md:mt-8 lg:mt-16 pb-64 lg:pb-96 items-center justify-items-center">
        <AppIcon
          icon="negative-circle-flag"
          size={60}
          cls="col-span-full col-start-1 justify-center"
        />
        <TextLockup
          headingClassName="col-span-5 lg:col-start-4 lg:col-span-8 px-4 lg:px-0 text-sh-h3 lg:text-sh-h2 font-semibold text-center "
          paraClassName="text-sh-h4 md:text-sh-h4 text-sh-gray-dark text-center col-span-5 lg:col-start-4 lg:col-span-8 px-4 lg:px-0"
          paragraph="It’s just a 404 error. No need to panic.
          Just navigate back to the home and try again."
          headingText="Oops &mdash; Something is up!"
        />
      </MainContent>
    </PageLayout>
  );
};

export default NotFoundPage;
